<template>
  <div class="pagination">
    <el-pagination
      background
      :current-page="currentPage4"
      :pager-count="5"
      :page-sizes="[10, 20, 30, 40, 50, 60, 80, 100]"
      layout="total, prev, pager, next, sizes, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    total: { // 总条数
      type: Number,
      default: 0
    },
    params: { // 分页的页码和页的条数
      type: Object,
      default: () => { return { pageSize: 10, pageNum: 1 } }
    },
    inArticle: { // 到多少条的数据
      type: Number,
      default: 0
    },
    getDataList: { // 操作数据的函数
      type: Function,
      default: () => { }
    }
  },
  data() {
    return {
      currentPage4: 0
    }
  },
  watch: {
    'params.pageNum': {
      handler(newVal) {
        this.currentPage4 = newVal
      }
    }
  },
  methods: {
    // 点击页数或者行数的函数
    handleSizeChange(page) {
      this.params.pageSize = page
      this.getDataList()
    },
    handleCurrentChange(page) {
      this.params.pageNum = page
      this.currentPage4 = page
      // 当时点击页数的时候就传一个参数过去，判断不用回到第一页，其他的
      // 时候都需要回到第一页
      this.getDataList(true)
    }
  }
}
</script>
<style scoped lang="scss">
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
}
.pagination .el-pagination {
  display: inline-block;
}
.pagination .numberBranches {
  display: inline-block;
  color: $mainFontColor;
  font-size: 14px;
}
.pagination .numberBranches span {
  color: rgb(64,158,255);
  margin: 0 4px;
}
/deep/ .el-input .el-input__inner {
  height: 28px;
  line-height: 28px;
}
</style>
