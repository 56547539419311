<template>
  <div class="tableConponents">
    <el-table
      ref="table-list"
      v-loading="loading"
      size="small"
      :border="isBorder"
      :data="listData"
      highlight-current-row
      element-loading-text="拼命加载中"
      class="table-list"
      :cell-style="{ borderColor: '#EBEBEB' }"
      :header-cell-style="{ borderColor: '#EBEBEB' }"
      :max-height="maxHeight"
      @expand-change="expandChange"
      @selection-change="handleSelectionChange"
    >
      <slot name="dynamicInsert" />
      <!-- 多选 -->
      <el-table-column
        v-if="selection"
        type="selection"
        fixed="left"
        width="56"
      />
      <!-- 单选 -->
      <el-table-column v-if="radioShow" fixed="left" width="56">
        <template slot-scope="scope">
          <el-radio
            v-model="radioRow"
            :label="scope.row.id"
            name="radioRow"
            @change="getRadioRow"
          >
            .
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in itemData"
        :key="index"
        align="left"
        :prop="item.prop"
        :min-width="item.width || ''"
        :label="item.label"
        :fixed="item.fixed || false"
        :show-overflow-tooltip="overflowTooltip"
      >
        <!-- 表头加上*号 -->
        <template slot="header">
          <span v-if="item.required" class="required">*</span>
          {{ item.label }}
        </template>
        <template slot-scope="scope">
          <!-- 判断是哪一个值 -->
          <slot
            :name="item.slotName || item.prop"
            :row="scope.row"
            :index="scope.$index"
          >
            <!-- eslint-disable vue/no-v-html -->
            <span
              v-if="item.html"
              :class="item.limited ? '' : ''"
              v-html="scope.row[item.prop]|| '-'"
            />
            <span v-else-if="item.type === 'dictionaries'">{{
              scope.row | formatType(item.prop, item.dictType)
            }}</span>
            <span v-else-if="item.type === 'money'">{{
              scope.row[item.prop] | numberToCurrency
            }}</span>
            <span
              v-else-if="!item.child && !item.path && !item.operation"
            >{{ scope.row[item.prop] | dataConversion }}{{ item.unit }}</span>
            <router-link
              v-else-if="item.path"
              class="linkPath"
              :to="{
                path: item.path,
                query: { id: scope.row[item.pathParameter] },
              }"
            >
              {{ scope.row[item.prop] || item.name || '-' }}
            </router-link>
            <!-- 点击可操作的 -->
            <span
              v-else-if="item.operation"
              class="linkPath"
              @click="operationalApproach(item.operation, scope.row)"
            >
              {{ scope.row[item.prop] || item.name|| '-' }}
            </span>
            <div
              v-for="(val, num) in item.child"
              v-else
              :key="num"
            >
              <span
                v-if="
                  (val.value || val.id || val.dictId) == scope.row[item.prop]
                "
              >{{ val.label || val.name || val.dictName || '-' }}</span>
            </div>
          </slot>
        </template>
      </el-table-column>
      <el-table-column
        v-if="operationButton && operationButton.length > 0"
        :width="operationButtonWidth||getOperationButtonWidth"
        align="left"
        fixed="right"
        label="操作"
      >
        <template v-if="operationButtonObj[scope.$index]" slot-scope="scope">
          <div
            v-for="(item, index) in operationButtonObj[scope.$index]"
            :key="index"
            class="button"
          >
            <el-button
              v-if="index < 2"
              size="mini"
              type="text"
              :icon="item.icon"
              @click="handleEvent(item, scope.row, scope.$index)"
            >
              {{ item.label }}
            </el-button>
            <el-button
              v-if="
                operationButtonObj[scope.$index].length === 3 && index === 2
              "
              size="mini"
              type="text"
              :icon="item.icon"
              @click="handleEvent(item, scope.row, scope.$index)"
            >
              {{ item.label }}
            </el-button>
          </div>
          <el-dropdown
            v-if="operationButtonObj[scope.$index].length > 3"
            trigger="click"
          >
            <span class="el-dropdown-link">
              <el-button size="mini" type="text"> 更多操作 </el-button>
            </span>
            <el-dropdown-menu slot="dropdown" class="tab-close-item">
              <el-dropdown-item
                v-for="item in operationButtonObj[scope.$index].slice(
                  2,
                  operationButtonObj[scope.$index].length
                )"
                :key="item.id"
              >
                <p @click="handleEvent(item, scope.row, scope.$index)">
                  {{ item.label }}
                </p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
/**
   operationButton是有哪些按钮   val，num表示判断这个按钮是否要存在的   没有这两个参数的
   都是可以的  handleEvent为事件  bType选择为按钮的样式类型 label为按钮的名字了   如果有child表示有多个判断条件要同时满足
   如果是有negation为true  则表示要取反
   childOne则为有一个就满足就出现按钮
   withParameters 为操作时要携带的参数

 * label为表头的名字   prop为表头相应的字段  child  为如果需要判断时候的执行
 *
 * value和label为判断时候的值跟所选项   当有path的时候就显示为链接  跳转在别的页面  name为链接时渲染的文字
*/
export default {
  filters: {
    formatType(row, propType, dictType) {
      if (!row || !propType || !dictType) return ''
      const dictionaryEntry = JSON.parse(localStorage.dictionaryEntry)
      const propTypeList = propType.split('/')
      // 通过key 找到对应的值
      const valueArr = []
      propTypeList.forEach((item) => {
        valueArr.push(row[item])
      })
      // 判断字典类型是否为数组（多个）
      if (dictType instanceof Array) {
        let dictName = ''
        dictType.forEach((element, index) => {
          const data = dictionaryEntry[element].find(
            (i) => i.dictId === valueArr[index]
          )
          if (data) {
            dictName =
              dictName +
              data.dictName +
              `${index === dictType.length - 1 ? '' : '/'}`
          }
        })
        return dictName
      } else {
        // 一个字典类型时
        const data = dictionaryEntry[dictType].find(
          (i) => i.dictId === valueArr[0]
        )
        if (data) return data.dictName
      }
    },
    dataConversion(value) {
      if (isNaN(value) || value) {
        return value
      }
      if (value === 0) return 0
      return '-'
    }
  },
  props: {
    // 操作按钮有哪些
    operationButton: {
      type: Array,
      default: () => []
    },
    // 单选
    radioShow: {
      default: false,
      type: Boolean
    },
    // 是否显示提示
    overflowTooltip: {
      default: true,
      type: Boolean
    },
    // 多选
    selection: {
      default: false,
      type: Boolean
    },
    // 操作按钮有哪些
    // eslint-disable-next-line vue/require-default-prop
    operationButtonWidth: {
      type: Number
    },
    // table表头数据
    itemData: {
      default: () => [],
      type: Array
    },
    // table数据
    listData: {
      default: () => [],
      type: Array
    },
    // 要修改的数据
    loading: {
      default: false,
      type: Boolean
    },
    // 是否需要边框
    isBorder: {
      default: false,
      type: Boolean
    },
    maxHeight: {
      type: [Number, String],
      default: 'auto'
    }
  },
  data() {
    return {
      buttonNumber: 0,
      expandChangeArr: [],
      radioRow: {},
      // 字典表
      dictionaryEntry: JSON.parse(localStorage.dictionaryEntry),
      operationButtonObj: {} // 操作按钮数组
    }
  },
  computed: {
    // 计算宽度
    getOperationButtonWidth() {
      const buttonsData = { ...this.operationButtonObj }
      const buttonKeys = Object.keys(buttonsData)
      if (buttonKeys.length === 0) return
      // 通过文字的个数来计算宽度了
      let buttonNum = 0
      let textNum = 0
      if (buttonKeys.length === 1) {
        buttonNum = buttonsData[buttonKeys].length
      }
      buttonKeys.forEach((item) => {
        if (buttonNum < buttonsData[item].length) {
          buttonNum = buttonsData[item].length
        }
        let singleTextNum = 0
        buttonsData[item].forEach(val => {
          if (val.label)singleTextNum += val.label.length
          if (val.icon && !val.label)singleTextNum += 2
        })
        textNum = singleTextNum > textNum ? singleTextNum : textNum
      })
      const width = buttonNum > 3 ? 276 : ((buttonNum * 26) + (textNum * 14))
      return width > 0 ? width : 72
    }
  },
  watch: {
    // 计算操作栏的宽度了
    operationButton: {
      handler(newVal) {
        if (newVal.length > 0) {
          let num = 0
          newVal.map((item) => {
            if (item.val || item.child) {
              num++
            }
          })
          this.buttonNumber = Math.ceil(newVal.length - num / 2)
        }
      },
      immediate: true,
      deep: true
    },
    listData: {
      handler(newVal) {
        const buttonData = {}
        if (newVal.length > 0) {
          newVal.forEach((row, index) => {
            buttonData[index] = []
            this.operationButton.forEach((item) => {
              if (
                // 是否取反
                (item.negation && row[item.val] !== item.num) ||
                // 多个判断条件  要同时满足才能出现
                (item.child && this.handleButtonDisplay(item.child, row)) ||
                // 多个判断条件   有一个满足就出现
                (item.childOne &&
                  this.handleButtonDisplayOne(item.childOne, row)) ||
                (item.val && row[item.val] === item.num && !item.negation) ||
                (item.val && row[item.val] && !item.negation && !item.num && item.num !== 0) ||
                // 其他情况
                (!item.negation &&
                  !item.child &&
                  !item.childOne &&
                  !item.num &&
                  !item.val)
              ) {
                buttonData[index].push({ ...item })
              }
            })
          })
        }
        this.$nextTick(() => {
          this.operationButtonObj = { ...buttonData }
          this.$forceUpdate()
        })
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    // const documentClientHeight = document.documentElement.clientHeight || window.innerHeight
    // let tablesTop = document.querySelector('.tableConponents').getBoundingClientRect().top;
    // this.$nextTick(() => {
    //   this.maxHeight = documentClientHeight - tablesTop - 80;
    // })
  },
  methods: {
    // 把选中的商品清除或者加上
    accessSelected(arr) {
      if (arr.constructor === Array) {
        arr.forEach((row) => {
          this.$refs['table-list'].toggleRowSelection(row)
        })
      }
      if (arr && arr.length === 0) this.$refs['table-list'].clearSelection()
    },
    // 展开下面的子节点
    handlerSpread(data, type) {
      this.$refs['table-list'].toggleRowExpansion(data, type)
    },
    expandChange(row, arr) {
      if (arr.length > this.expandChangeArr.length) {
        this.$emit('getExpandChangeRow', row)
      }
      this.expandChangeArr = arr
    },
    operationalApproach(fun, row) {
      fun(row)
    },
    // 单选
    getRadioRow() {
      const obj = this.listData.find((item) => item.id === this.radioRow) || {}
      this.$emit('getCheckData', obj)
    },
    // 多选
    handleSelectionChange(val) {
      this.$emit('getCheckData', val)
    },
    // 时间操作
    handleEvent(item, row, index) {
      item['handleEvent'](row, item.withParameters, index)
    },
    // 判断多个条件同时满足
    handleButtonDisplay(arr, row) {
      let falg = false
      falg = arr.every((item) => {
        if (!item.num) return row[item.val] // 如果没有num的话就表示这个是有数据就可了
        return row[item.val] === item.num // 如果有num就要比较数据是否相等
      })
      return falg
    },
    // 判断有一个满足就出现按钮
    handleButtonDisplayOne(arr, row) {
      let falg = false
      falg = arr.some((item) => {
        if (!item.num) return row[item.val] // 如果没有num的话就表示这个是有数据就可了
        return row[item.val] === item.num // 如果有num就要比较数据是否相等
      })
      return falg
    }
  }
}
</script>

<style lang="scss">
.tableConponents {
  margin-top: 16px;
  .el-table {
    width: 100%;
    .el-table__fixed-right{
      box-shadow: 0 0 10px rgba(0, 0, 0, .1)!important;
    }
    .el-table__header {
      .required{
        color: red;
      }
      .el-table__cell {
        padding: 15px 0 !important;
      }
      tr th {
        color: #999!important;
        font-size: 14px!important;
        font-weight: 500;
      }
      tr th:first-of-type {
        .cell {
          padding-left: 24px !important;
        }
      }
    }
    .el-table__body {
      .el-table__cell {
        padding: 12.5px 0;
      }
      tr td:first-of-type {
        .cell {
          padding-left: 24px !important;
        }
      }
      tr td {
        .cell {
          font-size: 14px!important;
          color: #666!important;
        }
      }
    }
    .linkPath {
      text-decoration: underline;
      color: blue;
      cursor: pointer;
    }
    .el-radio__label {
      display: none;
    }
  }
  .button {
    display: inline-block;
    button {
      margin-right: 24px;
    }
    .el-button--text {
      color: #007eff;
      font-size: 14px!important;
    }
    .el-button--mini i{
      font-size: 24px;
    }

  }
  .el-button--mini span{
      font-size: 14px!important;
      color: #007eff!important;
    }
  .button:last-of-type button {
    margin-right: 0px;
  }
}
</style>
