import { postRequest, getRequest } from './index'
// 企业开户信息分页
export const cmpAccountPage = (data, successCallback, failureCallback) => {
  postRequest('/cmp/account/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 公司实名认证接口
export const cmpBaseinfoAuth = (data, successCallback, failureCallback) => {
  postRequest('/cmp/baseinfo/auth', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 个人实名认证接口
export const userAuth = (data, successCallback, failureCallback) => {
  postRequest('/user/auth', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 新增跟修改企业开户信息
export const addAndEditCmpAccount = (data, successCallback, failureCallback) => {
  postRequest(data[0].id ? '/cmp/account/update' : '/cmp/account/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 禁用启用企业开户信息
export const cmpAccountEnableOrDisablt = (data, successCallback, failureCallback) => {
  postRequest('/cmp/account/enableOrDisablt', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 充值新增
export const inoutLogPayAdd = (data, successCallback, failureCallback) => {
  postRequest('/fund/inoutLog/payAdd', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 会员提现新增
export const inoutLogOutAdd = (data, successCallback, failureCallback) => {
  postRequest('/fund/inoutLog/outAdd', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 会员提现账户详情
export const cashAccountDetail = (data, successCallback, failureCallback) => {
  postRequest('/fund/cmpBankinfo/cashAccountDetail', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 会员提现账户添加
export const cashAccountAdd = (data, successCallback, failureCallback) => {
  postRequest('/fund/cmpBankinfo/cashAccountAdd', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 会员开户信息分页
export const cmpBankinfoPage = (data, successCallback, failureCallback) => {
  postRequest('/fund/cmpBankinfo/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 开户信息详情
export const cmpBankinfoDetail = (data, successCallback, failureCallback) => {
  postRequest('/fund/cmpBankinfo/detail', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 开户信息新增
export const cmpBankinfoAdd = (data, successCallback, failureCallback) => {
  postRequest('/fund/cmpBankinfo/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 账户信息分页
export const accountInfoPage = (data, successCallback, failureCallback) => {
  postRequest('/fund/accountInfo/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 查看资金明细
export const flowDetailPage = (data, successCallback, failureCallback) => {
  postRequest('/fund/flowDetail/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合同模板
// 合同模板分页
export const compactServiceListPage = (data, successCallback, failureCallback) => {
  postRequest('/compactService/list', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合同模板分页
export const compactServicePage2cmp = (data, successCallback, failureCallback) => {
  postRequest('/compactService/page2cmp', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合同模板客户端所有的
export const compactServiceGetCompactByType = (compactType, successCallback, failureCallback) => {
  getRequest(`compactService/getCompactByType?compactTypes=${compactType}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合同模板新增跟修改
export const compactServiceSaveAndUpdata = (data, successCallback, failureCallback) => {
  postRequest('/compactService/saveAndUpdata', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合同模板删除
export const compactServiceDelete = (data, successCallback, failureCallback) => {
  postRequest('/compactService/delete', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 保付子合约签约
export const abcBailPaySubContractSign = (data, successCallback, failureCallback) => {
  postRequest(`/abc/bailPay/subContractSign`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
