<template>
  <!-- 账户信息 -->
  <div class="accountInformationPageBox">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :reset="true" :form-inline="formInline" :search-form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <router-link :to="{ path: '/enterpriseInformation/addTopUp' }">
          <el-button size="small" type="success">
            充值
          </el-button>
        </router-link>
      </div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      >
        <template #itemType="{ row }">
          <span v-if="row.itemName === 1">{{ $store.getters.getDictionaryItem('fund_type')&&$store.getters.getDictionaryItem('fund_type').length > 0 ? $store.getters.getDictionaryItem('fund_type').find(item => item.dictId === String(row.itemType)).dictName : '' }}</span>
          <span v-else-if="row.itemName === 3">交易{{ $store.getters.getDictionaryItem('fund_type') && $store.getters.getDictionaryItem('fund_type').length > 0 ? $store.getters.getDictionaryItem('fund_type').find(item => item.dictId === String(row.itemType)).dictName : '' }}</span>
          <span v-else>{{ $store.getters.getDictionaryItem('fund_name') && $store.getters.getDictionaryItem('fund_name').length > 0 ? $store.getters.getDictionaryItem('fund_name').find(item => item.dictId === String(row.itemName)).dictName : '' }}{{ $store.getters.getDictionaryItem('fund_type') && $store.getters.getDictionaryItem('fund_type').length > 0 ? $store.getters.getDictionaryItem('fund_type').find(item => item.dictId === String(row.itemType)).dictName : '' }}</span>
        </template>
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
    <!-- 资金明细弹出窗 -->
    <!-- <el-dialog title="资金明细" :visible.sync="dialogVisible" width="62%">
      <el-tabs v-model="editableTabsValue" type="border-card" @tab-click="changeTabs">
        <el-tab-pane v-for="(item, index) in editableTabs" :key="index" :label="item" :name="`${index}`">
          表格  分页
          <Table :item-data="financialDetailsItemData" :list-data="financialDetailsListData" />
          分页
          <Pagination
            :params="financialDetailsFormInline"
            :total="financialDetailsTotal"
            :in-article="financialDetailsListData.length"
            :get-data-list="getFundDetails"
          />
        </el-tab-pane>
      </el-tabs>
    </el-dialog> -->
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination'
import { timestampToTime } from '@/unit/index'
import { accountInfoPage, flowDetailPage } from '@/http/agentApi/enterpriseInformation'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      financialDetailsFormInline: {},
      editableTabs: ['全部', '充值', '提现', '交易'],
      editableTabsValue: 0,
      financialDetailsItemData: [
        { label: '流水号', prop: 'id', width: 160 },
        { label: '业务编码', prop: 'busiNo', width: 160 },
        { label: '操作时间', prop: 'createTime', width: 160 },
        { label: '资金科目', prop: 'itemType', child: [{ value: 1, label: '充值' }, { value: 2, label: '提现' }, { value: 3, label: '冻结' }, { value: 4, label: '解冻' }, { value: 5, label: '支付' }, { value: 6, label: '收入' }] },
        { label: '交易金额', prop: 'amount' },
        { label: '可用余额', prop: 'endAvlbAmt' }
      ],
      financialDetailsTotal: 0,
      financialDetailsListData: [],
      cmpId: '',
      dialogVisible: true,
      itemData: [
        // { label: '会员编码', prop: 'cmpId', width: 180 },
        { label: '结算银行 ', prop: 'settleBankName', width: 180 },
        { label: '总金额(元)', prop: 'totalFund', width: 120 },
        { label: '可用余额(元)', prop: 'avlbFund', width: 120 },
        { label: '冻结金额(元)', prop: 'frzFund', width: 120 },
        { label: '可提现金额(元)', prop: 'avlbCashFund', width: 120 }
      ],
      operationButton: [
        { bType: 'primary', label: '资金明细', handleEvent: this.viewFundDetails },
        { negation: true, num: 0, val: 'avlbCashFund', bType: 'success', label: '提现', handleEvent: this.withdrawOperation }
      ],
      formItemArr: [
        { type: 'select', label: '结算银行', value: 'settleBankId', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('settleBankName') }
      ],
      loading: false,
      total: 0,
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      }
    }
  },
  methods: {
    // 点击tab的时候
    changeTabs() {
      switch (this.editableTabsValue) {
        case '1':
          this.financialDetailsFormInline.itemType = 1
          this.getFundDetails()
          break
        case '2':
          this.financialDetailsFormInline.itemType = 2
          this.getFundDetails()
          break
        case '3':
          this.financialDetailsListData = []
          break

        default:
          this.financialDetailsFormInline.itemType = ''
          this.getFundDetails()
          break
      }
    },
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      this.formInline.cmpId = JSON.parse(localStorage.getItem(`${sessionStorage.getItem('systemPlatformRoles') === '03' ? 'userInfoCust' : 'userInfoAgent'}`)).cmpId
      accountInfoPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.listData.forEach(item => {
          if (item.settleBankId === 'ABC') {
            item.totalFund = '-'
            item.avlbFund = '-'
            item.frzFund = '-'
            item.avlbCashFund = '-'
          }
        })
        this.total = res.data.total
      })
    },
    // 查看资金明细
    viewFundDetails(row) {
      // this.editableTabsValue = '0'
      this.financialDetailsFormInline.itemType = ''
      this.financialDetailsFormInline.cmpId = row.cmpId
      this.financialDetailsFormInline.settleBankId = row.settleBankId
      this.$router.push({
        path: '/enterpriseInformation/fundDetails',
        query: { ...this.financialDetailsFormInline }
      })
    },
    // 提现进行跳转
    withdrawOperation(row) {
      if (row.avlbCashFund <= 0) {
        this.$message.error('可提现金额小于等于0！')
        return
      }
      this.$router.push({ path: '/enterpriseInformation/addWithdrawDeposit', query: { ...row }})
    },
    getFundDetails(type) {
      if (!type) this.financialDetailsFormInline.pageNum = 1
      flowDetailPage(this.financialDetailsFormInline, res => {
        this.financialDetailsListData = [...res.data.pageData]
        this.financialDetailsTotal = res.data.total
        this.financialDetailsListData.forEach(item => {
          item.createTime = timestampToTime(item.createTime)
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.accountInformationPageBox {
  width: 100%;

}
</style>
